function tabSwiper(selector, option) {
  var swiper = null
  var banner = selector + ' .tab-swiper-pane'
  var tabbtn = $(selector).find('.tab-swiper-menu').children()
  var params = {
    speed: 600,
    slidesPerView: 1,
    on: {
      slideChangeTransitionStart: function () {
        var idx = this.activeIndex
        tabbtn.eq(idx).addClass('active').siblings().removeClass('active')
      },
    },
  }
  for (var key in option) {
    params[key] = option[key]
  }
  swiper = new Swiper(banner, params)
  tabbtn.eq(0).addClass('active').siblings().removeClass('active')
  tabbtn.bind('click', function (event) {
    var $this = $(this)
    var index = $this.index()
    event.preventDefault()
    $this.addClass('active').siblings().removeClass('active')
    swiper.slideTo(index)
  })
}

function tabSwitch(selector, event) {
  var tabbtn = $(selector).find('.tab-menu').children()
  var tabcon = $(selector).find('.tab-pane').children()
  tabbtn.eq(0).addClass('active').siblings().removeClass('active')
  tabcon.eq(0).addClass('active').siblings().removeClass('active')
  tabbtn.bind(event, function (event) {
    var $this = $(this)
    var index = $this.index()
    event.preventDefault()
    tabcon.hide()
    $this.addClass('active').siblings().removeClass('active')
    tabcon.eq(index).addClass('active').siblings().removeClass('active')
    tabcon.eq(index).show()
  })
}
